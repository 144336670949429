<template>
  <b-card title="Statements">
    <statement-filters @filtersUpdated="filtersChangeHandler" />
    <statements-list :filters="filters" />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import StatementsList from '../../components/Statement/StatementsList.vue'
import StatementFilters from '../../components/Statement/StatementFilters.vue'

export default {
  components: {
    BCard,
    StatementsList,
    StatementFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
