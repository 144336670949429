<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-group
          label="Area"
          label-for="area"
        >
          <custom-v-select
            id="area"
            v-model="selectedAreas"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="areaOptions"
            label="text"
            placeholder="Select Area"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Salesman"
          label-for="salesman"
        >
          <custom-v-select
            id="salesman"
            v-model="selectedSalesmans"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="salesmanOptions"
            label="text"
            placeholder="Select Salesman"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Party"
          label-for="party"
        >
          <custom-v-select
            id="party"
            v-model="selectedParties"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="partyOptions"
            label="text"
            placeholder="Select Party"
            multiple
            :close-on-select="false"
            :deselect-from-dropdown="true"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Due in days"
          label-for="due-in-days"
        >
          <b-form-input
            id="due-in-days"
            v-model="dueInDays"
            placeholder="Enter Due in days"
            type="number"
            @input="filtersChangeHandler"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
  },
  data() {
    return {
      areaOptions: [],
      selectedAreas: [],
      salesmanOptions: [],
      selectedSalesmans: [],
      dueInDays: null,
      selectedParties: [],
      partyOptions: [],
    }
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/areas_options')
        .then(res => {
          this.areaOptions = res.data.data
        })

      axios.get('/accounts/salesmans_options')
        .then(res => {
          this.salesmanOptions = res.data.data
        })

      axios.get('/accounts/buyers_options')
        .then(res => {
          this.partyOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = { }
      if (this.selectedAreas.length > 0) {
        filtersdata.areas = this.selectedAreas.map(area => area.id)
      }
      if (this.selectedSalesmans.length > 0) {
        filtersdata.salesmans = this.selectedSalesmans.map(salesman => salesman.id)
      }
      if (this.selectedParties.length > 0) {
        filtersdata.parties = this.selectedParties.map(party => party.id)
      }
      if (this.dueInDays) {
        filtersdata.due_in_days = this.dueInDays
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },
}
</script>
